import { RCategoryImages, RTypeLampImages } from '../types/Enums';

export const getEquipAvatar = (name, type, subtype) => {

  var ret = '';
  if(!name?.length){
    name = 'Cs';
  }

  if(type == 'seed'){
    const baseUrl = 'https://bucket.growdiaries.com/static/ct-lg/';
    let sanitized = name.replace(/[-\s]/g, '');
    sanitized = sanitized.replace(/([A-Z])/g, '_$1').replace(/^_/, '');  
    const parts = sanitized.split('_').map(part => {
      return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
    });
    let formattedName = parts.join('').substring(0, 2);

    formattedName = formattedName.charAt(0).toUpperCase() + formattedName.slice(1).toLowerCase();

    const fileName = `${formattedName}_m.webp`;
    ret = `${baseUrl}${fileName}`;
  }else if(type == 'lamp'){    
    if(!subtype){
      ret = RTypeLampImages[1];
    }else{
      ret = RTypeLampImages[subtype];
    }
  }else{
    console.log('type', type);
    console.log('RCategoryImages', RCategoryImages);
    ret = RCategoryImages[type];
  }

  

  return ret;

  
  
}